var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reporting.bmmb-funding")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("reporting.bmmb-funding")))]), _c('div', {
    staticClass: "row text-center mt-4"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("upload-doc")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "file",
      "id": "customFile",
      "name": "document"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  }), _c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.submitFile
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")])], 1)])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }